import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Form, Col, Row, ListGroup, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import {fetchLinkedScripts, fetchMessage, fetchMessageHistory} from "../services/fetchServices";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";

import IdleMessage from "./components/IdleMessage";
import EvaluationConversation from "./components/EvaluationConversation";
import ButtonOpenModal from "@/components/lead/ButtonOpenModal";
import HelpPopover from "@/components/shared/HelpPopover";
import ProductsRecommendation from "../MessageHistory/components/ProductsRecommendation";

import HourGlassIcon from "@/../assets/images/ai_assistant/hourglass-done.png";

import SleepingFaceIcon from "@/../assets/images/ai_assistant/sleeping-face.png";
import * as S from "./styles";
import ButtonExport from "@/components/leadster_ia/shared/ButtonExport";
import ModalHistoricExport from "@/components/leadster_ia/cards/Analytics/export/ModalHistoricExport";
import historicParamsExport from "@/components/leadster_ia/cards/Analytics/store/historicParamsExport";


const MessageHistory = () => {
  const FILTER_CONFIGS = {
    "cv-liked": { type: "conversation", value: true },
    "cv-disliked": { type: "conversation", value: false },
    "rs-liked": { type: "response", value: true },
    "rs-disliked": { type: "response", value: false },
    "rs-unassessed": { type: "response", value: "unassessed" },
  };

  useCreateYourAiAssistant("/history", "analytics");

  const { assistantId } = useParams();

  const [showMessages, setShowMessages] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [messageList, setMessageList] = useState([]);
  const [messageHistoryList, setMessageHistoryList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scriptFilter, setScriptFilter] = useState([]);
  const [scriptSelectFilter, setScriptSelectFilter] = useState([]);
  const [conversationFilter, setConversationFilter] = useState(null);
  const [evaluationFilter, setEvaluationFilter] = useState(null);
  const [responseEvaluationFilter, setResponseEvaluationFilter] = useState(null)

  const handleScriptFilterChange = (event) => {
    setScriptSelectFilter(event.target.value === "all" ? [null] : event.target.value);
  }

  const handleConversationFilterChange = (event) => {
    setConversationFilter(event.target.value === "all" ? [null] : event.target.value);
  }

  const handleEvaluationFilterChange = (event) => {
    setEvaluationFilter([null]);
    setResponseEvaluationFilter(null);

    const config = FILTER_CONFIGS[event.target.value];
    if (!config) return;

    if (config.type === "conversation") {
      setEvaluationFilter(config.value);
      return;
    }

    setResponseEvaluationFilter(config.value);
  }

  const loadScriptFilter = async () => {
    const { linkedScripts } = await fetchLinkedScripts({ assistantId })

    setScriptFilter(linkedScripts);
    setScriptSelectFilter(linkedScripts?.map(({ id }) => id));
  }

  const loadMessages = async () => {
    const { messages } = await fetchMessage({
      page: 1,
      scripts: scriptSelectFilter,
      conversation: conversationFilter,
      evaluationConversation: evaluationFilter,
      assistantId,
      responseEvaluation: responseEvaluationFilter
    });

    setMessageList(messages);
    setShowMessages(true);

    fillHistoricParamsExport(messages);
  };

  const loadMessagesHistory = async (leadId) => {
    const { messagesHistory } = await fetchMessageHistory({
      selectedMessage: leadId,
      page: 1,
      scripts: scriptSelectFilter,
      conversation: conversationFilter,
      evaluationConversation: evaluationFilter,
      assistantId,
      responseEvaluation: responseEvaluationFilter
    });

    setMessageHistoryList(messagesHistory);
  };

  const fillHistoricParamsExport = (messages) => {
    historicParamsExport.setState({
      disable_button: _.isEmpty(messages),
      assistant_id: assistantId,
      script_id: scriptSelectFilter,
      conversation: _.isArray(conversationFilter) || _.isEmpty(conversationFilter) ? "all" : conversationFilter,
      review: _.isArray(evaluationFilter) || _.isEmpty(evaluationFilter) ? "all" : evaluationFilter,
    });
  };

  useEffect(() => {
    loadScriptFilter();
  }, []);

  useEffect(() => {
    loadMessages();
    setMessageHistoryList([]);
    setSelectedRecord({});
  }, [scriptSelectFilter, conversationFilter, evaluationFilter, responseEvaluationFilter]);

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <Form.Select onChange={handleScriptFilterChange} defaultValue={"all"}>
            <option value="all">{I18n.t("views.leadster_ai.page.analytics.history.all_flows")}</option>
            {scriptFilter?.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select onChange={handleConversationFilterChange} defaultValue={"all"}>
            <option value="all">{I18n.t("views.leadster_ai.page.analytics.history.all_answers")}</option>
            <option value="done">{I18n.t("views.leadster_ai.page.analytics.history.answered")}</option>
            <option value="not_found">{I18n.t("views.leadster_ai.page.analytics.history.unanswered")}</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Select onChange={handleEvaluationFilterChange} defaultValue={"all"}>
            <option value="all">{I18n.t("views.leadster_ai.page.analytics.history.all_reviews")}</option>
            <option value="cv-liked">{I18n.t("views.leadster_ai.page.analytics.history.positive_reviews")}</option>
            <option value="cv-disliked">{I18n.t("views.leadster_ai.page.analytics.history.negative_reviews")}</option>
            <option value="rs-liked">{I18n.t("views.leadster_ai.page.analytics.history.response_liked")}</option>
            <option value="rs-disliked">{I18n.t("views.leadster_ai.page.analytics.history.response_disliked")}</option>
            <option value="rs-unassessed">{I18n.t("views.leadster_ai.page.analytics.history.response_unassessed")}</option>
          </Form.Select>
        </Col>
        <Col md={2}>
          <ButtonExport Modal={ModalHistoricExport} disable={historicParamsExport.getState().disable_button} />
        </Col>
      </Row>

      <Row>
        <Col style={{ maxWidth: '469px' }}>
          {showMessages && messageList.length != 0 ? (
            <InfiniteScroll
              dataLength={messageList.length}
              hasMore={hasMore}
              inverse={false}
              height={625}
            >
              <ListGroup>
                {messageList.map((record) => (
                  <S.ListGroupItem
                    key={record.lead_id}
                    active={record.lead_id === selectedRecord?.lead_id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedRecord(record);
                      loadMessagesHistory(record.lead_id);
                    }}
                  >
                    <div className="mb-2 text-secondary">
                      <time>{dayjs(record.created_at).format(I18n.t("js.formats.default.date_time"))}</time>
                    </div>
                    <S.Answer className="mb-2 fw-bolder">Visitante: {record.question}</S.Answer>
                    <S.Message className="fw-light" >
                      {record.assistant_name}: {record.products_recommendation.length ? (
                        <ProductsRecommendation urls={record.products_recommendation} />
                      ) : (
                        record.answer
                      )}
                    </S.Message>
                  </S.ListGroupItem>
                ))}
              </ListGroup>
            </InfiniteScroll>
          ) : (
            <IdleMessage
              title={I18n.t("views.leadster_ai.page.analytics.history.idle_messages.title")}
              description={I18n.t("views.leadster_ai.page.analytics.history.idle_messages.description")}
              icon={{ imgUrl: HourGlassIcon, color: '#00CFFF' }}
            />
          )}
        </Col>

        <Col style={{ maxWidth: '469px' }}>
          {showMessages && messageHistoryList.length != 0 ? (
            <Card>
              <Card.Header className="bg-white d-flex justify-content-between">
                {selectedRecord.lead_deleted_at ? (
                  <div className="d-flex align-items-center fw-bold">
                    <i className="ti ti-user-x me-2 fs-2/3/4/5" />
                    <span>{I18n.t("views.leadster_ai.page.analytics.history.no_data")}</span>
                    <HelpPopover
                      className="ms-2"
                      content={I18n.t("views.leadster_ai.page.analytics.history.no_data_popover")}
                      placement="top"
                    />
                  </div>
                ) : (
                  <>
                    <div className="fw-bold">{I18n.t("views.leadster_ai.page.analytics.history.see_data")}</div>
                    <ButtonOpenModal className="btn-outline-primary" id={selectedRecord.lead_id} size="xl" icon="user" />
                  </>
                )}
              </Card.Header>
              <Card.Body className='d-flex flex-column justify-content-end'>
                <InfiniteScroll
                  dataLength={messageHistoryList.length}
                  next={() => { }}
                  hasMore={hasMore}
                  inverse={true}
                  height={458}
                >
                  {messageHistoryList.map(({
                    created_at,
                    question,
                    answer,
                    avatar,
                    assistant_name,
                    products_recommendation,
                    liked
                  }, key) => (
                    <div key={key}>
                      <S.MessageCard isAnswer>
                        <p className='m-0'>
                          {question}
                        </p>
                        <time>{dayjs(created_at).format(I18n.t("js.formats.default.date_time"))}</time>
                      </S.MessageCard>
                      <div className='d-flex align-items-end mb-4'>
                        <S.Avatar alt={assistant_name[0]} src={avatar} />
                        <div>
                          <S.MessageCard>
                            <p className='m-0'>
                              {products_recommendation.length ? <ProductsRecommendation urls={products_recommendation} /> : answer}
                            </p>
                            <time>{dayjs(created_at).format(I18n.t("js.formats.default.date_time"))}</time>
                          </S.MessageCard>
                          {liked !== null && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>{I18n.t("views.leadster_ai.page.analytics.history.response_evaluated")}</Tooltip>
                              }
                            >
                              <i className={`fa fa-thumbs-${liked ? "up" : "down"} mt-1 ms-3`} />
                            </OverlayTrigger>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </Card.Body>
              <EvaluationConversation
                leadId={selectedRecord.lead_id}
                scriptId={selectedRecord.script_id}
                assistantId={selectedRecord.ai_assistant_id}
                isLike={selectedRecord.like}
                onClick={() => { loadMessages(); }}
              />
            </Card>
          ) : (
            <IdleMessage
              title={I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.title")}
              description={I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.description")}
              icon={{ imgUrl: SleepingFaceIcon, color: '#007DFF' }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default MessageHistory;
