import { useState } from "react";

import CreatableSelect from "react-select/creatable";

import { can } from "@/lib/user-permission";

import DeleteButton from "@/components/scripts/ScriptEditorParts/DeleteButton";
import CloneBlockButton from "@/components/scripts/ScriptEditorParts/CloneBlockButton";

import * as S from "./styles";

import * as Data from "./data/options"

const PERMISSION_SCOPE = Object.freeze({
  action: "manage",
  subject: "script_flow",
});

const I18N_SCOPE = "views.manage_flows.script_editor.ads_events";

const ScriptBlockAdsEvents = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const [metaEventSwitch, setMetaEventSwitch] = useState(props.definition.meta_event);
  const [metaEventOptions, setMetaEventOptions] = useState(props.definition.meta_event_options)

  const [metaPixelSwitch, setMetaPixelSwitch] = useState(props.definition.meta_pixel);
  const [metaPixelInput, setMetaPixelInput] = useState(props.definition.meta_pixel_code)

  const [googleEventSwitch, setGoogleEventSwitch] = useState(props.definition.google_event);
  const [googleEventOptions, setGoogleEventOptions] = useState(props.definition.google_event_options);

  const [googleTagSwitch, setGoogleTagSwitch] = useState(props.definition.google_tag);
  const [googleTagInput, setGoogleTagInput] = useState(props.definition.google_tag_code);

  const definition = (def) => {
    const mergedDef = Object.assign(props.definition, def);
    props.setDefinition(mergedDef);
  };

  const handleChange = (value, setEvent, defData) => {
    setEvent(value);

    definition(defData);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <S.BulbMessage>
            <i className="ti ti-bulb"/>
            {I18n.t(`${I18N_SCOPE}.bulb_message`)}
          </S.BulbMessage>
        </div>
      </div>

      <div className="row gx-2 mb-4">
        <div className="col">
          <div className="input-group input-group-flat">
            <button type="button" className="btn btn-primary">
              <i className="ti ti-brand-telegram me-2" />
              {I18n.t(`${I18N_SCOPE}.title`)}
            </button>

            <input
              type="text"
              className="form-control"
              value={I18n.t(`${I18N_SCOPE}.describe`)}
              readOnly={true}
            />
          </div>
        </div>

        <div className="col-auto">
          <CloneBlockButton onDuplicate={props.onDuplicate} enabled={hasPermission} />
        </div>

        <div className="col-auto">
          <DeleteButton onDelete={props.onDelete} permissionScope={PERMISSION_SCOPE} />
        </div>
      </div>

      <div className="row">
        {/*Meta Event*/}
        <div className="col-12">
          <label className="form-check form-check-inline form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={metaEventSwitch}
              disabled={!hasPermission}
              onChange={(e) => handleChange(e.target.checked, setMetaEventSwitch, { meta_event: e.target.checked })}
            />
            <span className="form-check-label"><b>{I18n.t(`${I18N_SCOPE}.meta.events.label_switch`)}</b></span>
          </label>
        </div>
        <div className="col-12 mb-4">
          <label className="form-label">{I18n.t(`${I18N_SCOPE}.meta.events.label_input`)}</label>
          <CreatableSelect
            isMulti
            options={Data.metaEvents}
            isDisabled={!metaEventSwitch || !hasPermission}
            value={metaEventOptions}
            onChange={(value) => handleChange(value, setMetaEventOptions, { meta_event_options: value })}
            placeholder={I18n.t(`${I18N_SCOPE}.meta.events.placeholder_input`)}
          />
        </div>

        {/*Meta Pixel*/}
        <div className="col-12">
          <label className="form-check form-check-inline form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={metaPixelSwitch}
              disabled={!hasPermission || !metaEventSwitch}
              onChange={(e) => handleChange(e.target.checked, setMetaPixelSwitch, { meta_pixel: e.target.checked })}
            />
            <span className="form-check-label"><b>{I18n.t(`${I18N_SCOPE}.meta.pixel.label_switch`)}</b></span>
          </label>
        </div>
        <div className="col-12">
          <div className="mb-3">
            <label className="form-label">
              {I18n.t("views.manage_flows.script_editor.pixel_code")}
              <a
                className="form-help ms-2"
                href={I18n.t("shared.helpscout.ga4_trigger_events")}
                target="_blank"
                data-bs-toggle="tooltip"
                title={I18n.t("shared.actions.click_to_learn_more")}
              >
                <i className="fa fa-info"/>
              </a>
            </label>

            <span className="fs-5">
              {I18n.t(`${I18N_SCOPE}.span_info`)}
            </span>

            <input
              type="text"
              className="form-control mt-1"
              value={metaPixelInput}
              disabled={!metaPixelSwitch || !hasPermission || !metaEventSwitch}
              onChange={(e) => handleChange(e.target.value, setMetaPixelInput, { meta_pixel_code: e.target.value }) }
              placeholder={I18n.t(`${I18N_SCOPE}.meta.pixel.placeholder_input`)}
            />
          </div>
        </div>
      </div>

      <hr/>

      <div className="row">
        {/*Google Event*/}
        <div className="col-12">
          <label className="form-check form-check-inline form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={googleEventSwitch}
              disabled={!hasPermission}
              onChange={(e) => handleChange(e.target.checked, setGoogleEventSwitch, { google_event: e.target.checked })}
            />
            <span className="form-check-label"><b>{I18n.t(`${I18N_SCOPE}.google.events.label_switch`)}</b></span>
          </label>
        </div>
        <div className="col-12 mb-4">
          <label className="form-label">{I18n.t(`${I18N_SCOPE}.google.events.label_input`)}</label>
          <CreatableSelect
            isMulti
            options={Data.googleEvents}
            isDisabled={!googleEventSwitch || !hasPermission}
            value={googleEventOptions}
            onChange={(value) => handleChange(value, setGoogleEventOptions, { google_event_options: value })}
            placeholder={I18n.t(`${I18N_SCOPE}.google.events.placeholder_input`)}
          />
        </div>

        {/*Google Tag*/}
        <div className="col-12">
          <label className="form-check form-check-inline form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={googleTagSwitch}
              disabled={!hasPermission || !googleEventSwitch}
              onChange={(e) => handleChange(e.target.checked, setGoogleTagSwitch,{ google_tag: e.target.checked })}
            />
            <span className="form-check-label"><b>{I18n.t(`${I18N_SCOPE}.google.tag.label_switch`)}</b></span>
          </label>
        </div>
        <div className="col-12">
          <div className="mb-3">
            <label className="form-label">{I18n.t(`${I18N_SCOPE}.google.tag.label_input`)}</label>
            <span className="fs-5">
            {I18n.t(`${I18N_SCOPE}.span_info`)}
            </span>
            <input
              type="text"
              className="form-control mt-1"
              value={googleTagInput}
              disabled={!googleTagSwitch || !hasPermission || !googleEventSwitch}
              onChange={(e) => handleChange(e.target.value, setGoogleTagInput, { google_tag_code: e.target.value }) }
              placeholder={I18n.t(`${I18N_SCOPE}.google.tag.placeholder_input`)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ScriptBlockAdsEvents;
