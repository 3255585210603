import { can } from "@/lib/user-permission";

const useDefaultOptionsList = () => {
  const defaultAllowedOption = can({ action: "manage", subject: "script_flow" });
  const allowedAiInteraction = can({ action: "manage", subject: "leadster_ai" });

  return [
    {
      grouperText: I18n.t("views.manage_flows.scripts.assistant_question"),
      itens: [
        {
          action: "say",
          icon: "ti ti-message-dots",
          text: I18n.t("views.manage_flows.scripts.bot_message"),
          isNew: false,
          isAllowed: defaultAllowedOption,
          show: true,
          configuration: { uid: "", say: "" },
        },
        {
          action: "iaInteraction",
          icon: "ti ti-message-chatbot",
          text: I18n.t("views.manage_flows.scripts.ia_interaction"),
          isNew: false,
          isAllowed: allowedAiInteraction,
          show: allowedAiInteraction,
          configuration: {
            iaInteraction: true,
            uid: "",
            label: "Leadster AI",
            say: "",
            endConversationLabel: I18n.t("views.manage_flows.script_editor.ia_interaction.settings.default_end_conversation_label"),
            botId: null,
          },
        },
      ],
    },
    {
      grouperText: I18n.t("views.manage_flows.scripts.user_response"),
      itens: [
        {
          action: "save",
          icon: "ti ti-edit-circle",
          text: I18n.t("views.manage_flows.scripts.user_response"),
          isNew: false,
          isAllowed: defaultAllowedOption,
          show: true,
          configuration: {
            uid: "",
            type: "text",
            save: I18n.t("views.manage_flows.script_editor.default_input_name"),
          },
        },
        {
          action: "selector",
          icon: "ti ti-list-details",
          text: I18n.t("views.manage_flows.script_editor.list_of_options"),
          isNew: false,
          isAllowed: defaultAllowedOption,
          show: true,
          configuration: {
            uid: "",
            save: I18n.t("views.manage_flows.script_editor.default_input_name"),
            from: [],
            version_hash: null,
            version_date: null,
            version_key: null,
          },
        },
      ],
    },
    {
      grouperText: I18n.t("views.manage_flows.scripts.chat_functions"),
      itens: [
        {
          action: "if",
          icon: "ti ti-arrows-split",
          text: I18n.t("views.manage_flows.scripts.conditional"),
          isNew: false,
          isAllowed: defaultAllowedOption,
          show: true,
          configuration: {
            if: {
              mainOperator: "OR",
              conditions: [{ field: "", operator: "=", values: [] }],
            },
            reject_lead: false,
            then: [],
          },
        },
        {
          action: "jivochat",
          icon: "ti ti-messages",
          text: I18n.t("views.manage_flows.scripts.open_jivochat"),
          isNew: false,
          isAllowed: defaultAllowedOption,
          show: true,
          configuration: { uid: "", say: "", jivochat: true },
        },
        {
          action: "whatsapp",
          icon: "ti ti-brand-whatsapp",
          text: I18n.t("views.manage_flows.scripts.redirect_to_whatsapp"),
          isNew: false,
          isAllowed: defaultAllowedOption,
          show: true,
          configuration: {
            uid: "",
            say: "",
            label: "WhatsApp",
            whatsapp: true,
            useAutoRedirect: false,
            useCancelAutomaticRedirectTime: false,
            useSchedule: false,
            version_hash: null,
            version_date: null,
            version_key: null,
          },
        },
        {
          action: "autoRedirect",
          icon: "ti ti-external-link",
          text: I18n.t("views.manage_flows.scripts.redirect_to_a_link"),
          isNew: false,
          isAllowed: defaultAllowedOption,
          show: true,
          configuration: { uid: "", say: "", link: "", autoRedirect: true },
        },
        {
          action: "scheduler",
          icon: "ti ti-calendar-event",
          text: I18n.t("views.manage_flows.scripts.scheduler"),
          isNew: false,
          isAllowed: defaultAllowedOption,
          show: true,
          configuration: {
            uid: "",
            label: I18n.t("views.manage_flows.scheduler_block.scheduling"),
            say: "",
            scheduler: true,
            email_field: "",
            subject: "",
            description: "",
            introduction_text: I18n.t("views.manage_flows.scripts.schedule_day_and_time"),
            representatives: [],
            use_custom_schedule: false,
            schedules: [],
            duration_time: 30,
            max_booking_days: 30,
            tolerance_time: 60,
            increment_time: 30,
            use_break_before: false,
            time_break_before: 30,
            use_break_after: false,
            time_break_after: 30,
            version_hash: null,
            version_date: null,
            version_key: null,
          },
        },
        /* TODO Ads Events will be activated in lead-3009 part 2 */
        /*{
          action: "adsEvents",
          icon: "ti ti-brand-telegram",
          text: I18n.t("views.manage_flows.script_editor.ads_events.title"),
          isNew: true,
          isAllowed: defaultAllowedOption,
          show: true,
          configuration: {
            uid: "",
            say: "",
            adsEvents: true,
            meta_event: false,
            meta_event_options: [],
            meta_pixel: false,
            meta_pixel_code: "",
            google_event: false,
            google_event_options: [],
            google_tag: false,
            google_tag_code: "",
          },
        },*/
      ],
    },
  ];
};

export default useDefaultOptionsList;
