import axios from "@/lib/axios";
import swal from "sweetalert";

export const handleKeyDown = (event) => {
  switch (event.key) {
    case "Enter":
      event.preventDefault();
      return;
  }
};

export const requestExportData = async (url, params) => {
  await axios.post(url, params).then(() => {
    swal(
      I18n.t("views.leadster_ai.export.modal.processing"),
      I18n.t("views.leadster_ai.export.modal.processing_text"),
      "success")
      .then(() => {
      window.location.reload()
    });
  }).catch((error) => {
    throw new Error(error.response.data.message);
  });
};
