import { useEffect, useState } from "react";

import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

import HelpPopover from "@/components/shared/HelpPopover";
import EmailCreatableSelect from "@/components/leadster_ia/shared/EmailCreatableSelect";

import supportParamsExport from "@/components/leadster_ia/cards/Analytics/store/supportParamsExport";

import { classBinding } from "@/utils/helpers";

import {
  handleKeyDown,
  requestExportData,
} from "../ModalUtils";

const I18N_SCOPE = "views.leadster_ai.export.modal";

const ModalSupportExport = ({show = false, onClose}) => {
  const {
    register,
    handleSubmit,
    reset
  } = useForm();

  const [emails, setEmails] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const supportParams = supportParamsExport.getState();

  const handleEmails = (val) => {
    setEmails(val);
  }

  const onSubmit = async (data) => {
    try{
      setIsSubmitting(true);

      const url = "/ai/leadster/analytics/export-supports";

      const params = {
        assistant_id: data.assistant_id,
        script_id: data.script_id,
        version: data.version,
        start_date: data.start_date,
        end_date: data.end_date,
        emails: emails,
      };

      await requestExportData(url, params)

    } catch(exception) {
      swal("", exception.message, "error").then(_r => "");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    reset();
  }, [supportParams]);

  return (
    <Modal show={show} className="modal-blur" backdrop="static" size="lg" centered onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">{I18n.t(`${I18N_SCOPE}.support.title`)}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <Modal.Body>
          <Row>
            <p>{I18n.t(`${I18N_SCOPE}.support.subtitle`)}</p>
          </Row>
          <Form.Control type="hidden" {...register("assistant_id")} value={supportParams.assistant_id} />
          <Form.Control type="hidden" {...register("script_id")} value={supportParams.script_id} />
          <Form.Control type="hidden" {...register("start_date")} value={supportParams.start_date} />
          <Form.Control type="hidden" {...register("end_date")} value={supportParams.end_date} />
          <Row className={"mb-4"}>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="fs-3">
                  <b>{I18n.t(`${I18N_SCOPE}.support.label_select`)}</b>
                  <HelpPopover
                    className="ms-1"
                    content={I18n.t(`${I18N_SCOPE}.support.popover_info_html`)}
                  />
                </Form.Label>
                <Form.Select
                  {...register("version")}>
                  <option value="full">{I18n.t(`${I18N_SCOPE}.support.option_select_full`)}</option>
                  <option value="partial_one">{I18n.t(`${I18N_SCOPE}.support.option_select_one`)}</option>
                  <option value="partial_two">{I18n.t(`${I18N_SCOPE}.support.option_select_two`)}</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="fs-3"><b>{I18n.t(`${I18N_SCOPE}.support.interval`)}</b></Form.Label>
                <Form.Control value={supportParams.data_range_str} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="fs-3"><b>{I18n.t(`${I18N_SCOPE}.mail_label`)}</b></Form.Label>
                <EmailCreatableSelect onBlur={handleEmails} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" className={classBinding({ "btn-loading": isSubmitting}, "btn btn-primary")}>
            <i className="ti ti-table-export me-1"/>
            {I18n.t(`${I18N_SCOPE}.button_label`)}
          </Button>
        </Modal.Footer>

      </Form>
    </Modal>
  );
};

export default ModalSupportExport;
