import { useCallback, useState, useRef } from "react";

import GuideModal from "@/components/shared/GuideModal";

import guideContents from "./guideContents";

const I18N_SCOPE = "views.manage_flows.script_editor.ia_interaction";

const AssistantSettings = ({ onDefinition, blockDefinitions }) => {
  const [endConversationLabel, setEndConversationLabel] = useState(blockDefinitions.endConversationLabel);

  const endConversationTextGuideRef = useRef();

  const handleChangeDefaultLabel = (e) => {
    setEndConversationLabel(e.target.value);
    onDefinition({ endConversationLabel: e.target.value });
    trackUpdateEndConversationLabel();
  };

  const openGuideEndConversation = (e) =>  {
    e.preventDefault();
    endConversationTextGuideRef.current.show();
  };

  const trackUpdateEndConversationLabel = useCallback(
    _.throttle(() => {
      MixpanelService.track("leadster_ai_block_change_copy_btn");
    }, 60000),
    []
  );

  return (
    <>
      <fieldset className="form-fieldset mt-3">
        <div className="d-flex align-items-center mb-2">
          <span className="bg-primary-lt text-white avatar me-2">
            <i className="ti ti-user-share fs-2 text-primary" />
          </span>

          <div className="fw-medium">{I18n.t(`${I18N_SCOPE}.settings.title`)}</div>
        </div>

        <div>{I18n.t(`${I18N_SCOPE}.settings.subtitle`)}</div>

        <hr className="my-3" />

        <label className="form-label">
          <i className="ti ti-edit icon text-primary me-1" />
          {I18n.t(`${I18N_SCOPE}.settings.custom_end_conversation_label`)}

          <a className="ms-2 fw-bold" onClick={openGuideEndConversation}>
            {I18n.t(`${I18N_SCOPE}.settings.how_it_works`)}
            <i className="ti ti-player-play icon ms-1" />
          </a>

          <GuideModal
            guideContents={guideContents}
            autoShow={false}
            finishButtonText={I18n.t('guide_modal.alright_button')}
            ref={endConversationTextGuideRef}
          />
        </label>

        <div className="form-group">
          <input
            type="text"
            className="form-control"
            maxLength={40}
            onChange={handleChangeDefaultLabel}
            value={endConversationLabel}
          />
        </div>
      </fieldset>

      <fieldset className="form-fieldset mt-3">
        <div className="fw-medium">
          {I18n.t(`${I18N_SCOPE}.settings.advanced_settings.description`)}
          <a
            href={I18n.t(`${I18N_SCOPE}.settings.advanced_settings.tutorial_link`)}
            onClick={() => MixpanelService.track("leadster_ai_block_learn_more")}
            className="ms-1"
            target="_blank"
          >
            {I18n.t(`${I18N_SCOPE}.settings.advanced_settings.link_label`)}
            <i className="ti ti-external-link ms-1" />
          </a>
        </div>
      </fieldset>
    </>
  );
};

export default AssistantSettings;
