import { useEffect } from "react";

import Pagination from "@/components/shared/Pagination";
import RowRender from "@/components/dashboard/PageRank/RowRender";
import HelpPopover from "@/components/shared/HelpPopover";
import PermissionsGate from "@/components/permissions/PermissionsGate";

import useFilters from "./useFilters";
import usePagination from "@/hooks/usePagination";
import useCurrentUserStore from "@/store/currentUserStore";

import { classBinding } from "@/utils/helpers";

import * as S from "./style";

const PERMISSION_SCOPE = Object.freeze({
  action: "manage",
  subject: "analytics_page_rank",
});

const PageRank = () => {
  const filters = useFilters();
  const { isTrialing, isImplemented } = useCurrentUserStore((state) => state.currentUser);

  const {
    data: pageRank,
    paginationData,
    isLoading,
    setCurrentPage,
    currentPage,
    setPerPage,
    error,
  } = usePagination("/dashboard-metrics/page-rank", {
    filters: {
      date_start: filters.startDate,
      date_end: filters.endDate,
      script_id: filters.scriptId,
      mobile: filters.mobile,
      score: filters.score,
    },
    defaultDataValue: [],
    errorFeedbackLib: false,
    dependencies: [filters],
  });

  const rowIndex = () => {
    if (paginationData.current_page == 1) return 1;

    return ((paginationData.current_page * paginationData.limit_value) - paginationData.limit_value + 1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-start">
        <div className="mt-3 mb-2 d-block">
          <h3 className="text-primary h1">
            {I18n.t("views.dashboard.page_rank.title")}
          </h3>
        </div>
        <div className="ps-3 flex-grow-1">
          <hr className="w-100" />
        </div>
      </div>
      <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon iconCentered usePlaceholderError>
        <div>
          <div className={classBinding({ "content-loading": isLoading }, "table-responsive mt-3")}>
            <S.MainTable isEmptyResult={pageRank.length === 0} isFirstPage={currentPage === 1}>
              <thead>
              <tr>
                <th></th>
                <th>{I18n.t("views.dashboard.page_rank.table.headers.text_1")}</th>
                <th>{I18n.t("views.dashboard.page_rank.table.headers.text_2")}</th>
                <th>{I18n.t("views.dashboard.page_rank.table.headers.text_3")}</th>
                <th>
                  {I18n.t("views.dashboard.page_rank.table.headers.text_4")}
                  <HelpPopover
                    className="ms-2"
                    content={I18n.t("views.dashboard.page_rank.table.headers.popover_html")}
                  />
                </th>
                <th className="optimization">{I18n.t("views.dashboard.page_rank.table.headers.text_5")}</th>
              </tr>
              </thead>
              <tbody>
              <RowRender
                isTrialing={isTrialing}
                isImplemented={isImplemented}
                pageRank={pageRank}
                calculateRowIndex={rowIndex}
                error={error}
              />
              </tbody>
            </S.MainTable>

            <Pagination
              className="mb-0"
              isPageRangeEnabled
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              onPerPageChange={setPerPage}
              pageCount={paginationData.total_pages}
              perPage={paginationData.limit_value}
            />
          </div>
        </div>
      </PermissionsGate>
    </div>
  );
};

export default PageRank;
